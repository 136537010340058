import './scss/app.scss'

//// MODULE IMPORTS
// Swiper
// core styles
import 'swiper/css'

// modules styles
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import Swiper, {Navigation, Pagination} from 'swiper';
// GSAP
import {CSSPlugin, Expo, gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'

Swiper.use([Navigation, Pagination]);

gsap.registerPlugin(CSSPlugin, ScrollToPlugin);

//// CODE BELOW

// Scroll to anchor
document.addEventListener('DOMContentLoaded', () => {
    const trigger = document.querySelectorAll('[data-scroll-anchor="true"]')

    function scrollToAnchor(e) {
        e.preventDefault()

        const target = document.querySelector(this.hash)
        const targetPos = target.getBoundingClientRect().top

        gsap.to(window, {
            duration: 1,
            scrollTo: {
                y: targetPos
            },
            ease: Expo.easeInOut,
            onStart: function () {
                const menu = document.querySelector('.navbar-menu')
                const body = document.body;

                if (menu.classList.contains('menu-open')) {
                    body.classList.remove('menu-is-open');
                    menu.classList.remove('menu-open');
                    setTimeout(function () {
                        menu.style.display = '';
                    }, 650);
                }
            }
        })
    }

    const removeHash = () => {
        let uri = window.location.toString();

        if (uri.indexOf('#') > 0) {
            let cleanUri = uri.substring(0, uri.indexOf('#'));

            window.history.replaceState({}, document.title, cleanUri);
        }
    }

    const getOffsetTop = element => {
        let offsetTop = 0;
        while (element) {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        }
        return offsetTop;
    }

    // if (window.location.hash.indexOf('section-use-cases') > -1) {
    //     const target = document.querySelector(window.location.hash)
    //     const targetPos = getOffsetTop(target)
    //
    //     gsap.to(window, {
    //         duration: 1,
    //         scrollTo: {
    //             y: targetPos
    //         },
    //         ease: Expo.easeInOut,
    //         onComplete: removeHash
    //     })
    // }

    trigger.forEach(el => el.addEventListener('click', scrollToAnchor, false))
});

// Mobile menu
document.addEventListener('DOMContentLoaded', () => {
    const toggler = document.querySelector('.navbar-menu-toggler'),
        menu = document.querySelector('.navbar-menu'),
        body = document.body;

    let a, b;

    function toggleMenu() {
        var that = this;

        clearTimeout(b);

        if (!menu.classList.contains('menu-open')) {
            body.classList.add('menu-is-open');
            menu.style.display = 'flex';
            setTimeout(function () {
                menu.classList.add('menu-open');
            }, 50);
        } else {
            body.classList.remove('menu-is-open');
            menu.classList.remove('menu-open');
            b = setTimeout(function () {
                menu.style.display = '';
            }, 650);
        }
    }

    if (toggler) {
        toggler.addEventListener('click', toggleMenu, false);
    }
});

// Load single div backgrounds
document.addEventListener('DOMContentLoaded', () => {
    var image = document.querySelectorAll('img[data-bg="true"], [data-bg="true"]');

    for (var i = 0; i < image.length; i++) {
        (function (n) {
            var img = new Image();

            img.onload = imageFound;
            img.onerror = imageNotFound;
            img.src = image[n].src;

            function imageFound() {
                image[n].parentNode.style.backgroundImage = 'url(' + img.src + ')';

                if ($(image[n]).parent().is('.bg')) {
                    $(image[n]).parent().css({
                        opacity: '1'
                    });
                }
            }

            function imageNotFound() {
                image[n].parentNode.style.backgroundColor = '#F8F9FD';
            }
        }(i));
    }
});

// Accordion menu
document.addEventListener('DOMContentLoaded', () => {
    let opened = null

    let a;

    const toggler = document.querySelectorAll('[data-accordion]')

    const toggleVisibility = e => {
        if (window.innerWidth <= 768) {
            e.classList.toggle('accordion-open')
            e.previousElementSibling.classList.toggle('accordion-is-open')

            clearTimeout(a);

            if (e.classList.contains('accordion-open')) {
                e.style.display = 'block'
                e.style.maxHeight = e.scrollHeight + 'px';
            } else {
                e.style.maxHeight = '';
                a = setTimeout(function () {
                    e.style.display = ''
                }, 600);
            }
        }
    }

    const handleAccordion = e => {

        const clickedItem = e.parentElement.children[0].nextElementSibling

        toggleVisibility(clickedItem)

        if (!opened) {
            opened = clickedItem
        } else if (opened == clickedItem) {
            opened = null
        } else {
            toggleVisibility(opened)
            opened = clickedItem
        }

    }

    const handleClick = e => {

        if (e.target.dataset.accordion) {
            e.preventDefault();

            handleAccordion(e.target)
        }

    }

    document.addEventListener('click', handleClick)
});

// Global Navigation
document.addEventListener('DOMContentLoaded', () => {
    var trigger = $('[data-trigger="dropdown"]'),
        dropdownRoot = $('.dropdown-root'),
        dropdownBackground = $('.dropdown-background'),
        dropdownIndicator = $('.dropdown-indicator'),
        dropdownContainer = $('.dropdown-container'),
        dropdownSection = $('.dropdown-section'),
        body = $('body');

    var a, b, i = 0, target;

    function showNavigation(e) {
        target = $('.dropdown-section[data-dropdown="' + $(e.target).data('dropdown') + '"]');

        if (trigger.is(e.target)) {

            let position = $(e.target).offset().left - (parseInt(target.css('width')) / 2) + ($(e.target).innerWidth() / 2) <= 0 ?
                $(e.target).offset().left :
                $(e.target).offset().left - (parseInt(target.css('width')) / 2) + ($(e.target).innerWidth() / 2)

            let positionIndicator = $(e.target).offset().left - position + $(e.target).innerWidth() / 2 - 10

            dropdownRoot.css({
                width: target.css('width'),
            });

            dropdownSection.removeClass('active');

            dropdownSection.css({
                opacity: '',
            });

            dropdownRoot.show();

            setTimeout(function () {
                dropdownRoot.css({
                    transform: `translate3d(${position}px, 0, 0)`,
                    transition: i <= 0 ? 'none' : '',
                });

                i++;

                dropdownBackground.css({
                    height: target.innerHeight() + 'px',
                    transform: 'none',
                    opacity: '1',
                });

                dropdownIndicator.css({
                    transform: `translate3d(${positionIndicator}px, 0, 0) rotate(45deg)`,
                    opacity: '1',
                });

                dropdownContainer.css({
                    height: target.innerHeight() + 'px',
                });

                target.css({
                    opacity: '1',
                    transform: 'none',
                });

                target.addClass('active');
            }, 50);
        } else {
            clearTimeout(a);
            clearTimeout(b);
        }
    }

    function hideNavigation(e) {
        a = setTimeout(function () {
            dropdownSection.css({
                opacity: '',
                transform: '',
            });

            dropdownSection.removeClass('active');

            dropdownBackground.css({
                transform: '',
                opacity: ''
            });

            dropdownIndicator.css({
                opacity: ''
            });

            b = setTimeout(function () {
                dropdownRoot.hide();
                dropdownRoot.css({
                    width: '',
                });
                dropdownBackground.css({
                    background: '',
                });
            }, 250);
        }, 200);
    }

    function initMenu() {
        if ($(window).width() < 768) {
            if (!body.hasClass('has-mobile-navigation')) {
                body.addClass('has-mobile-navigation');

                body.off('mouseover');
            }
        } else {
            body.removeClass('has-mobile-navigation');

            body.off('click');

            body.on('click', function (e) {
                clearTimeout(a);
                clearTimeout(b);

                if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
                    return true;
                }
            });

            body.on('mouseover', function (e) {
                clearTimeout(a);
                clearTimeout(b);

                if (trigger.is(e.target) || trigger.has(e.target).length > 0 || dropdownSection.is(e.target) || dropdownSection.has(e.target).length > 0) {
                    showNavigation(e);
                } else {
                    hideNavigation(e);
                }
            });
        }
    }

    initMenu();
    $(window).on('resize', initMenu);
});

// Swiper - Team
document.addEventListener('DOMContentLoaded', () => {
    const swiper = new Swiper('.swiper-team', {
        slidesPerView: 1,
        spaceBetween: 30,
        breakpoints: {
            768: {
                slidesPerView: 'auto',
                spaceBetween: 40,
                slidesOffsetAfter: 30,
            }
        }
    });
});

// Reveal on scroll
document.addEventListener('DOMContentLoaded', () => {
    const options = {
        threshold: 0,
        rootMargin: '0px 0px -50px 0px',
    };

    var elementsArr = [
        'header',
        '.how-it-works .card',
        '.brand',
        '.mini-brands h6',
        '.hero-copy h1',
        '.hero-copy p',
        '.hero-copy .btn',
        '.sub-section-grid h3',
        '.sub-section-grid p',
        '.sub-section-grid .btn',
        '.quote p',
        '.quote .quote-author .photo',
        '.quote .quote-author .text',
        '.section-heading h2',
        '.section-heading h3',
        '.section-heading p',
        '.use-cases-card',
        '.wwd-text-container h2',
        '.wwd-text-container p',
        '.grid-card',
        '.cta',
        '.on-demand-oracle-data-container-column',
        '.table-fw-thead',
        '.table-fw-tr',
        '.how-text-container h3',
        '.how-text-container p',
        '.how-text-container .btn-link',
        '.how-card',
        '.content-grid-card',
        '.project-files-card',
        '.team-card',
        '.roadmap',
        '.roadmap-tick',
        '.chapter-title',
        '.events',
        // Images
        '.hero-image-wrapper',
        '.sub-section-image-wrapper',
        '.section-decor',
    ]

    let itemDelay = .15;

    let itemLoad = 0;

    function onEntry(entry) {
        itemLoad = 0;

        entry.forEach((change, i) => {

            if (change.isIntersecting) {

                itemLoad++;

                gsap.to(change.target, {
                    opacity: 1,
                    translateY: 0,
                    ease: Expo.easeOut,
                    duration: 2,
                    delay: itemLoad * itemDelay,
                }).then(function () {
                    observer.unobserve(change.target);

                    change.target.style.transform = '';
                    change.target.style.opacity = '';
                    change.target.style.willChange = '';
                });

            }
        });
    }

    // instantiate a new Intersection Observer
    let observer = new IntersectionObserver(onEntry, options);

    // list of cards
    let elements = document.querySelectorAll(elementsArr);

    const setProperties = e => {
        e.style.transform = 'translate3d(0, 2rem, 0)';
        e.style.opacity = '0';
        e.style.willChange = 'transform, opacity';
    }

    elements.forEach(elm => setProperties(elm))

    // loop through all elements
    // pass each element to observe method
    for (let elm of elements) {
        observer.observe(elm);
    }
});